import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { map, catchError } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';



@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {

  constructor(private authSrv: AuthenticationService,
    public router: Router,
    private toastService: NotificationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


      let jsonReq: HttpRequest<any> = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authSrv.token}`,
        },
      });

      // return next.handle(jsonReq);
      return next.handle(jsonReq).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do nothing for now
          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          console.log("---------Interceptor---------");
          console.log(error.status);
          if (error.status == 401) {
            this.toastService.errorMessage("Token Expired");
            //this.helper.presentToast("Token Expired");
            this.router.navigateByUrl('/login', { replaceUrl: true });
          }

          console.log("---------Interceptor---------");
          const status = error.status;

          const reason = error && error.error.reason ? error.error.reason : '';

          console.log('[Error From Interceptor]', error);
          this.toastService.errorMessage(error.error.message);

          // this.presentAlert(status, reason);
          return throwError(error);
        })
      );
  }

}
