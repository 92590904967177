import { Component, OnInit } from '@angular/core';
declare var $:any
@Component({
  selector: 'app-userapp',
  templateUrl: './userapp.component.html',
  styleUrls: ['./userapp.component.scss']
})
export class UserappComponent implements OnInit {

  constructor() { }

  ngOnInit() {

     $('.togglebtn').click(() => {
        $('.sidebar').toggleClass('active');
        $('.content').toggleClass('active');
     });

  }

}
