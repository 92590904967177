import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'maskPhoneNumber'
})
export class MaskUserPhonePipe implements PipeTransform {
  transform(phoneNumber: string): string {
    const firstPart = phoneNumber.slice(0, 5);
    const secondPart = phoneNumber.slice(9);
    return `${firstPart}****${secondPart}`;
  }
}
