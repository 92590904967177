import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnboardingComponent } from './layout/onboarding/onboarding.component';
import { UserappComponent } from './layout/userapp/userapp.component';


const routes: Routes = [
  {
    path: '',
    component: OnboardingComponent,
    children: [
      {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren: () => import('./components/onboarding/onboarding.module').then(m => m.OnboardingModule)
      }
    ]
  },
  {
    path: '',
    component: UserappComponent,
    children: [
      
      { 
        path: 'dashboard', 
        loadChildren: () => import('./components/userapp/dashboard/dashboard.module').then(m => m.DashboardModule) 
      },
      { 
        path: 'loan', 
        loadChildren: () => import('./components/userapp/loan/loan.module').then(m => m.LoanModule) 
      },
      {
        path: 'transact', 
        loadChildren: () => import('./components/userapp/transact/transact.module').then(m => m.TransactModule) 
      },
      { 
        path: 'wealth-plan', 
        loadChildren: () => import('./components/userapp/wealth-plan/wealth-plan.module').then(m => m.WealthPlanModule) 
      },
      { 
        path: 'pay-bills', 
        loadChildren: () => import('./components/userapp/pay-bills/pay-bills.module').then(m => m.PayBillsModule) 
      },
      { 
        path: 'payments', 
        loadChildren: () => import('./components/userapp/payments/payments.module').then(m => m.PaymentsModule) 
      },
      { 
        path: 'account', 
        loadChildren: () => import('./components/userapp/account/account.module').then(m => m.AccountModule) 
      }
    ]
  },
  
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
