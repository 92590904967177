import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account/account.service';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';
import { Helper } from 'src/app/shared/services/helper';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  userData: any;
  accounts: any;
  userProfile: any;
  constructor(
    public helper: Helper,
    public accountService: AccountService,
    public onBaordingSrv: OnboardingService,
    public router: Router,
  ) { }

  async ngOnInit() {
    await this.loadProfile();
    let userdata = this.helper.getData("userData");
    this.userData = JSON.parse(userdata != "undefined" ? userdata : "{}");
    let userprofile = this.helper.getData("userProfile");
    this.userProfile = JSON.parse(userprofile != "undefined" ? userprofile : "{}");
    console.log("Profile",this.userProfile);
    
    // console.log(JSON.parse(this.helper.getData("userData")));
    this.getAccounts_from_server();
  }
  getAccounts_from_server() {
    this.accountService.getAccounts().subscribe(
      (data) => {
        console.log("Accounts");
        this.accounts = (data.data)["account"];
        console.log("Account", this.accounts);

      },
      (error) => {
        console.log("Error getting accounts");
        console.log(error);
      }
    );
  }
  async loadProfile() {
    console.log("sdsdfsdgs");
    this.onBaordingSrv.loadProfile().subscribe(
      (res: any) => {
        this.helper.setData("userData", JSON.stringify(res.data.userData));
        this.helper.setData("userProfile", JSON.stringify(res.data.profile));
        
      },
      (error) => {
        console.log("Error getting accounts");
        console.log(error);
      }
    );
  }
  logout() {
    // this.userData.logout();
    localStorage.clear();
    this.router.navigateByUrl('/login', { replaceUrl: true });
  }

}
