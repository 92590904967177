import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OnboardingComponent } from './layout/onboarding/onboarding.component';
import { UserappComponent } from './layout/userapp/userapp.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AddHeaderInterceptor } from './core/interceptor/add-header.inteceptor';
import { MaskUserPhonePipe } from './shared/pipes/mask-user-phone.pipe';
import { SidebarComponent } from './layout/userapp/sidebar/sidebar.component';
import { HeaderComponent } from './layout/userapp/header/header.component';
import { ToastrModule } from 'ngx-toastr'; //Works

import { NgxFileDropModule } from 'ngx-file-drop';



import { NgxSpinnerModule } from "ngx-spinner";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BaseAPI } from './services/base/base';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { TooltipModule } from 'ng2-tooltip-directive';

import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    AppComponent,
    OnboardingComponent,
    UserappComponent,
    MaskUserPhonePipe,
    SidebarComponent,
    HeaderComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    NgxFileDropModule,
    TooltipModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RoundProgressModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    BaseAPI,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true
    }
],
schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
// o8uWmLGukCth/m06/hh4pNrobpc=
// o8uWmLGukCth_m06_hh4pNrobpc=
