import { Injectable } from "@angular/core";
import {
  newUser,
  createAccount,
  userProfile,
  Bvn,
  Password,
  verifyWithToken,
  resetPassword,
  login,
  neptuneAccount,
  verifyAccount,
  createBizAccount,
  security,
} from "../../interfaces/onboarding";
import { BaseAPI } from "../base/base";

@Injectable({
  providedIn: "root",
})
export class OnboardingService {
  constructor(private api: BaseAPI) {}
  createAccount(data: createAccount) {
    this.api.setActionUrl("/auth", "/signup");
    return this.api.post<newUser>(data);
  }

  verifyPhone(data: verifyWithToken) {
    this.api.setActionUrl("/auth", `/verify-phone?code=${data.token}`);
    return this.api.get<newUser>(`/auth/verify-phone?code=${data.token}`);
  }
  updateProfile(data: userProfile) {
    this.api.setActionUrl("/profiles", `/update_profile`);
    return this.api.update<newUser>(data);
  }
  updateBVN(data: Bvn) {
    this.api.setActionUrl("/profiles", `/update_bvn`);
    return this.api.update<newUser>(data);
  }
  igreeConcent(code){
    this.api.setActionUrl("/auth", `/igree-concent/${code}`);
    return this.api.get<newUser>(`/auth/igree-concent/${code}`);
  }
  verifyEmail(code){
    this.api.setActionUrl("/auth", `/verify-email/${code}`);
    return this.api.get<newUser>(`/auth/verify-email/${code}`);
  }
  verifyBVN(data: verifyWithToken) {
    this.api.setActionUrl("/auth", `/verify-bvn?code=${data}`);
    return this.api.get<newUser>(`/auth/verify-bvn?code=${data}`);
  }
  loadProfile() {
    this.api.setActionUrl("/auth", `/load-profile`);
    return this.api.get<newUser>(`/auth/load-profile`);
  }
  secure(data: Password) {
    this.api.setActionUrl("/profiles", `/set_password`);
    return this.api.update<newUser>(data);
  }
  requestResetCode(email: String) {
    this.api.setActionUrl("/auth", `/request-reset/${email}`);
    return this.api.post<any>([]);
  }
  requestRendEmail(email: String) {
    this.api.setActionUrl("/auth", `/request-resend/${email}`);
    return this.api.post<any>([]);
  }
  resetPassword(data: resetPassword) {
    this.api.setActionUrl("/auth", `/reset-password`);
    return this.api.post<any>(data);
  }
  login(data: login) {
    console.log("about to login...");

    this.api.setActionUrl("/auth", `/signin`);
    return this.api.post<any>(data);
  }
  uploadSelfie(data: any) {
    console.log("about to login...");
    this.api.setActionUrl("/profiles", `/upload_selfie`);
    return this.api.post<any>(data);
  }
  createSecret(data: security){
    this.api.setActionUrl("/profiles","/secret");
    return this.api.post<any>(data);
  }

  
  

  // use this to verify then .....
  hasNetptuneAccount(data: neptuneAccount) {
    this.api.setActionUrl("/auth", "/claim-account");
    return this.api.post<newUser>(data);
  }

  // Use this to verify

  //  params accountNO && otp
  verifyNeptuneAccount(data: verifyAccount) {
    this.api.setActionUrl("/auth", `/verify-account`);
    return this.api.post<newUser>(data);
  }

  createBizAccount(data: createBizAccount) {
    this.api.setActionUrl("/account", "/add-new-biz-account");
    return this.api.post<any>(data);
  }
  getSectorList() {
    this.api.setActionUrl("/account", "/get-sector-list");
    return this.api.get<any>("/account/get-sector-list");
  }
}
