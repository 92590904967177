import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/timeoutWith';
import { ApiResponse } from '../../interfaces/api-response';
import { environment } from '../../../environments/environment';

@Injectable()
export class BaseAPI {
  private baseUrl: string;
  private _actionUrl: string;
  private timeOut: number;

  constructor(public http: HttpClient) {
    this.baseUrl = environment.baseUrl;
    this.timeOut = 60000;
  }

  public get<T = any>(actionURL) {
    return this.http.get<ApiResponse<T>>(`${this.baseUrl}${actionURL}`)
      .timeoutWith(this.timeOut, this.handleTimeout());

  }

  public post<T = any>(input: any): Observable<ApiResponse<T>> {
    console.log("about to post...");
    
    return this.http.post<ApiResponse<T>>(`${this.baseUrl}${this._actionUrl}`, input)
      .timeoutWith(this.timeOut, this.handleTimeout());
  }

  public update<T = any>(input: any): Observable<ApiResponse<T>> {
    return this.http.put<ApiResponse<T>>(`${this.baseUrl}${this._actionUrl}`, input)
      .timeoutWith(this.timeOut, this.handleTimeout());
  }

  public delete<T = any>(): Observable<ApiResponse<T>> {
    return this.http.delete<ApiResponse<T>>(`${this.baseUrl}${this._actionUrl}`)
      .timeoutWith(this.timeOut, this.handleTimeout());
  }

  /**
   * Sets the action url for the current request
   *
   * @param {string} actionUrl
   * @param {string} [path='']
   * @memberof BaseProvider
   */
  public setActionUrl(actionUrl: string, path: string = '') {
    this._actionUrl = `${actionUrl}${path}`;
  }
  private handleTimeout(): Observable<ApiResponse<null>> {
    return new Observable(obs => obs.error({ error: { message: 'Request timed out' } }));
  }
}
