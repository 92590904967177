import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';






@Injectable({
  providedIn: 'root',
})
export class Helper {
  constructor(
    private spinner: NgxSpinnerService
  ) { }
  setData(name, data) {
    localStorage.setItem(name, data)
  }
  getData(name) {
    return localStorage.getItem(name);
  }
  startSpinner() {
    this.spinner.show();
  }
  hideSpinner() {
    this.spinner.hide()
  }

 
}
