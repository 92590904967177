import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastService: ToastrService) { }

  successMessage(message: string) {
    this.toastService.success(message, 'Success')
  }

  errorMessage(message: string) {
    this.toastService.error(message, 'Error');
  }

  infoMessage(message: string) {
    this.toastService.info(message, 'Information');
  }

  warningMessage(message: string) {
    this.toastService.warning(message, 'Warning');
  }
}
