import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/services/account/account.service';
import { NotificationService } from 'src/app/services/notification.service';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';
import { Helper } from 'src/app/shared/services/helper';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  userData: any;
  accounts: any;
  account_number: any;
  account_balance: any;
  noaccount: boolean = false;
  constructor(
    public helper: Helper,
    public accountService: AccountService,
    public notificationServe: NotificationService,
    public onBaordingSrv: OnboardingService
  ) { }

  async ngOnInit() {
    await this.loadProfile();
    let userdata = this.helper.getData("userData");
    this.userData = JSON.parse(userdata != "undefined" ? userdata : "{}");
    console.log(JSON.parse(this.helper.getData("userData")));
    this.getAccounts_from_server();
  }
  getAccounts_from_server() {
    this.accountService.getAccounts().subscribe(
      (data) => {
        console.log("Accounts");
        this.accounts = (data.data)["account"];
        if (this.accounts.length == 0) {
          console.log("no account");
          this.noaccount = true;

        } else {
          this.account_number = ((this.accounts)[0]).accountNo
          this.account_balance = ((this.accounts)[0]).balance.effectivebalance
        }
        console.log("Account", this.accounts.length);

      },
      (error) => {
        console.log("Error getting accounts");
        console.log(error);
      }
    );
  }

  async loadProfile() {
    console.log("sdsdfsdgs");
    this.onBaordingSrv.loadProfile().subscribe(
      (res: any) => {
        console.log("load profile");

        console.log(res);

        this.helper.setData("userData", JSON.stringify(res.data.userData));
        this.helper.setData("userProfile", JSON.stringify(res.data.profile));
      },
      (error) => {
        console.log("Error getting accounts");
        console.log(error);
      }
    );
  }
  addAccount() {
    try {
      this.helper.startSpinner();
      this.accountService.addNewAccount().subscribe(
        (data) => {
          if (data.status == true && data.data) {
            this.getAccounts_from_server();
            let accountNo = data.data["accountNo"];
            if (!accountNo) {
              this.helper.hideSpinner()
              this.notificationServe.errorMessage("There is a an error creating your account");
              return
            }
          } else {
            this.notificationServe.errorMessage("There is a an error creating your account");
          }
          this.helper.hideSpinner()
        },
        (error) => {
          console.log("Error getting accounts");
          this.notificationServe.errorMessage("There is a an error creating your account");
          console.log(error);

        }
      );
    } catch (error) {
      this.notificationServe.errorMessage("There is a an error creating your account");
      this.helper.hideSpinner();
    }
  }

}
