import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { map, tap } from "rxjs/operators";
// import { BASE_URL } from "../../../environments/environment";
import {
  Account,
  AccountDetails,
  Accounts,
  Beneficiary,
  buklTransfer,
  connectAccount,
  debitInst,
  fundAccountCash,
  fundAccountLink,
  fundAccountLinkPayStack,
  fundAccountPayStack,
  loanPayStack,
  nameenquiry,
  neptuneTransfer,
  newAccount,
  singleCredit,
} from "../../interfaces/account";
import { BaseAPI } from "../base/base";

interface AccountData {
  status: boolean;
  message: string;
  data: {
    account: Account[];
  };
}
@Injectable({
  providedIn: "root",
})
export class AccountService {
  constructor(private http: HttpClient, private api: BaseAPI) {}
  getAccounts() {
    this.api.setActionUrl("/account", "/my-account");
    return this.api.get<Accounts>("/account/my-account");
  }
  getPayment(code) {
    this.api.setActionUrl("/payments", `/link/${code}`);
    return this.api.get<Accounts>(`/payments/link/${code}`);
  }
  getOtherBeneficiaries() {
    this.api.setActionUrl("/account", "/my-other-beneficiaries");
    return this.api.get<Accounts>("/account/my-other-beneficiaries");
  }
  getOtherAccountDetails(data: nameenquiry) {
    this.api.setActionUrl("/account", "/details-others");
    return this.api.post<any>(data);
  }
  
  getTransactions(per_page, next, prev) {
    this.api.setActionUrl("/account", "/transactions");
    return this.api.get<Accounts>(
      `/account/transactions?per_page=${per_page}&next=${next}&prev=${prev}`
    );
  }
  otherTransfer(data: singleCredit) {
    this.api.setActionUrl("/account", "/other-transfer");
    return this.api.post<any>(data);
  }
  buklTransfer(data: buklTransfer) {
    this.api.setActionUrl("/account", "/bulk-transfer");
    return this.api.post<any>(data);
  }
  getAccountTransactions(accountNo: any, data) {
    this.api.setActionUrl("/account", `/transactions/${accountNo}`);
    this.api.setActionUrl("/account", `/transactions/${accountNo}`);
    return this.api.post<any>(data);
  }

  getBeneficiaries() {
    this.api.setActionUrl("/account", "/my-neptune-beneficiaries");
    return this.api.get<any>("/account/my-neptune-beneficiaries");
  }

  getProductList() {
    this.api.setActionUrl("/account", "/get-product-list");
    return this.api.get<any>("/account/get-product-list");
  }
  getConnectAccounts() {
    this.api.setActionUrl("/account", "/connect-account");
    return this.api.get<any>("/account/connect-account");
  }
  getFinancialInstitutions() {
    this.api.setActionUrl("/account", "/finincial-institions");
    return this.api.get<any>("/account/finincial-institions");
  }
  deleteConnectedAccounts(account) {
    this.api.setActionUrl("/account", `/connect-account/${account}`);
    return this.api.delete<any>();
  }
  connectAccount(data: connectAccount) {
    this.api.setActionUrl("/account", "/connect-account");
    return this.api.post<any>(data);
  }

  addOtherAccount(data: newAccount) {
    this.api.setActionUrl("/account", "/add-other-account");
    return this.api.post<any>(data);
  }
  // what does it do?

  // what will it return if success?

  // where do you need this?

  getAccountDetails(accountNumber) {
    this.api.setActionUrl("/account", "/details");
    return this.api.post<AccountDetails>({
      acctNo: accountNumber,
    });
  }
  fundAccountPayStack(data: fundAccountPayStack) {
    this.api.setActionUrl("/account", "/fund-account-paystack");
    return this.api.post<any>(data);
  }
  fundAccountLinkPayStack(data: fundAccountLinkPayStack) {
    this.api.setActionUrl("/account", "/fund-account-link-paystack");
    return this.api.post<any>(data);
  }
  
  fundAccountCash(data: fundAccountCash) {
    this.api.setActionUrl("/account", "/fund-account-cash");
    return this.api.post<any>(data);
  }
  fundAccountLink(data: fundAccountLink) {
    this.api.setActionUrl("/payments", "/generateLink");
    return this.api.post<any>(data);
  }
  neptuneTransfer(data: neptuneTransfer) {
    this.api.setActionUrl("/account", "/neptune-transfer");
    return this.api.post<any>(data);
  }
  rePayLaonPaystack(data: loanPayStack) {
    this.api.setActionUrl("/loan", "/rePayLoanPayStack");
    return this.api.post<any>(data);
  }
  rePayLaonAccount(data: fundAccountCash) {
    this.api.setActionUrl("/loan", "/rePayLoanNeptune");
    return this.api.post<any>(data);
  }
  debitI(data: debitInst) {
    this.api.setActionUrl("/account", "/debit");
    return this.api.post<any>(data);
  }
  addNewAccount() {
    this.api.setActionUrl("/account", "/add-new-account");
    return this.api.post<any>({});
  }
  addBeneficiary(data: Beneficiary) {
    this.api.setActionUrl("/account", "/add-new-beneficiary");
    return this.api.post<any>(data);
  }
  deleteBeneficiary(account) {
    this.api.setActionUrl("/account", `/delete-beneficiary/${account}`);
    return this.api.delete<any>();
  }
}
