import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
// import { TokenService } from '../token/token.service';
import { Subject } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService  {

  private baseUrl = environment.baseUrl;
  private readonly TOKEN_NAME = 'nvault_auth';
  private readonly PHONE_NUMB = 'nvault_user_phone';

  get token() {
    return localStorage.getItem(this.TOKEN_NAME);
  }

  get phoneNo() {
    return localStorage.getItem(this.PHONE_NUMB);
  }

  onBoardingRoutes = {
    signUp: 'auth/signup',
    verifyOtp: 'auth/verify-phone?code=',
    updateProfile: 'profiles/update_profile',
    resetPassword: 'auth/reset-password',
    verifyBVN: 'auth/verify-bvn?code=',
    setPassword: 'profiles/set_password',

  };

  private _refreshNeeded = new Subject<void>();

  get refreshed() {
    return this._refreshNeeded;
  }

  constructor(private http: HttpClient) { }


  signup(data) {
    return this.http.post(`${this.baseUrl}/${this.onBoardingRoutes.signUp}`, data)
    .pipe(
      tap((response: any) => {
        localStorage.setItem(this.TOKEN_NAME, response.data.token);
        localStorage.setItem(this.PHONE_NUMB, response.data.user.phone_number);
      }))
  }

  setPassword(pswrd) {
    return this.http.put(`${this.baseUrl}/${this.onBoardingRoutes.setPassword}`, pswrd);
  }

  verifyPhone(otp) {
    return this.http.get(`${this.baseUrl}/${this.onBoardingRoutes.verifyOtp}${otp}`);
  }

  verifyBVN(bvn) {
    return this.http.get(`${this.baseUrl}/${this.onBoardingRoutes.verifyBVN}${bvn}`);
  }

  updateProfile(data) {
    return this.http.put(`${this.baseUrl}/${this.onBoardingRoutes.updateProfile}`, data);
  }



  private handleError(err: any): Observable<never> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }


}
